import React, {useEffect, useState} from 'react';
import './style.scss';
import PostItem from "./PostItem";

export default function Posts({slug, posts}) {
    const [data, setData] = useState();

    useEffect(() => {
        setData(posts.reverse().filter((item, i) => item.slug !== slug && i < 4))
    }, []);

    if (!data) return null;

    return (
        <div className="">
            <div className='articles_section articles'>
                {data.map(item => <PostItem key={item.id} item={item}/>)}
            </div>
        </div>
    )
};
