import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import './style.scss';
import {API_URL} from "../../constant";
import Posts from "../../components/posts";
import Loader from "../../components/common/Loader";
import LeaveReply from "../../components/posts/LeaveReply";
import {useParams} from "react-router";
import {dateFormat} from "../../helper";
import {Helmet} from "react-helmet-async";

export default function Post() {
    const {slug} = useParams();
    const [data, setData] = useState();
    const [relatedPosts, setRelatedPosts] = useState()

    const getPostData = ()=> {
        if (slug) {
            fetch(`${API_URL}api/v1/blogs/${slug}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => res.json())
                .then(result => {
                    setData(result.data)
                })
                .catch(err => console.log(err, 'err'))
        }
    }

    const getRelatedPosts = () => {
        fetch(`${API_URL}api/v1/blogs`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(res => res.json())
            .then(result => setRelatedPosts(result.data))
            .catch(err => console.log(err, 'err'))

    }

    useEffect(() => {
        setData(null);
        window.scrollTo({top: 0, behavior: "smooth"})
        getPostData();
        getRelatedPosts();
    }, [slug])

    if (!data || !relatedPosts) return <div className="empty-wrapper"><Loader/></div>

    return (
        <div className="page-main">
            <Helmet>
                <title>FTS</title>
                <title>{data.title}</title>
                <meta name="title" content={data.seo_title || data.title} />
                <meta name="description" content={data.meta_description || "DescriptionDESCRIPTION"} />
                <meta name="keywords" content={data.meta_keywords} />
                <meta property="og:title" content={data.seo_title || data.title || "TitleTITLE"} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.fresnotraffic.school" />
                <meta property="og:image" content={data.image}/>
            </Helmet>
            <div>
                <div className="article_page main_page padding_main wrapper">
                    <h1 className='title'>{data.title}</h1>
                    <div className='main_image'>
                        <img src={data.image} alt='Article'/>
                        <div className="post-info">
                            <h5 className='date'><span className="icon-calendar"></span> {dateFormat(data.updated_at)}
                            </h5> |
                            <a href="#post-comments" className='comments-count'
                            ><span className="icon-bubble2"></span> {data?.comment.length} Comments
                            </a>|
                            <Link className="to-posts"
                                  to={`/category/${data.category.slug}`}>To {data.category.name} ⮞</Link>
                        </div>
                    </div>
                    <div className='article_info'>
                        <div className='desc' dangerouslySetInnerHTML={{__html: data.body}}></div>
                    </div>
                </div>
            </div>
            <div className='related_articles wrapper'>
                <h3 className=" related-title">YOU MIGHT ALSO LIKE</h3>
                <Posts posts={relatedPosts} slug={slug}/>
            </div>
            {data && <LeaveReply getPostData={getPostData} id={data.id} comments={data.comment.reverse()}/>}
        </div>
    )
}
