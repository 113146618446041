import './App.scss';
import Home from "./pages";
import Layout from "./components/layout";
import {Route, Routes} from "react-router";
import ContactUs from "./pages/contact";
import "../src/assets/icomoon/style.css";
import Post from "./pages/single-post";
import Index from "./pages/posts";
import {Helmet} from "react-helmet";
import image from "./assets/images/about.jpg";
import React from "react";
import {HelmetProvider} from "react-helmet-async";


function App() {
    return (
        <div className="App">
            <HelmetProvider>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/contact-us/" element={<ContactUs/>}/>
                        <Route path="/category/:type/" element={<Index/>}/>
                        <Route path="/:slug/" element={<Post/>}/>
                    </Routes>
                </Layout>
            </HelmetProvider>
        </div>
    )
}

export default App;
