import {useState} from "react";
import faqs from "../../data/faqs.json"

export default function Faqs() {
    const [active, setActive] = useState(null);

    return (
        <div className="faqs" id="faqs">
            <h3 className="faqs-title">{faqs.title}</h3>
            <h2 className="faqs-description">{faqs.description}</h2>
            <div className="faq">
                {faqs.list.map((item, index) => (
                    <div key={index} className="faq-item">
                        <input
                            onChange={(e)=> {
                                if(active===index){
                                    setActive(null)
                                }else{
                                    setActive(index)
                                }
                            }}
                            checked={active === index}
                            type="checkbox" id={"question" + index} name="q" className="questions"/>

                        <label htmlFor={"question" + index} className="question">
                            <h1>
                                {item.question}
                            </h1>
                        </label>
                        <p className="answers">
                            {item.answer}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}