import InputField from "../common/InputField";
import {useFormik} from "formik";
import {API_URL} from "../../constant";
import * as yup from "yup";
import {useEffect, useState} from "react";
import {dateFormat, staticColors} from "../../helper";

const loginSchema = yup.object({
    comments: yup.string().required("Comment is required"),
    name: yup.string().required("Name is required"),
    email: yup.string().email('Invalid email').required('No email provided'),
});

export default function LeaveReply({getPostData, comments, id}) {
    const [response, setResponse] = useState();
    const [data, setData] = useState([]);
    const [allComm, setAllComm] = useState(false);
    const [disable, setDisable] = useState(false);
    const [saveAuthor, setSaveAuthor] = useState(false);
    const initialValues = JSON.parse(localStorage.getItem("comment-author")) || {
        name: "",
        email: "",
        website: "",
        comments: ""
    }

    useEffect(() => {
        if (comments.length <= 5) {
            setData(comments)
        } else {
            allComm ? setData(comments) :
                setData(comments.slice(0, 5))
        }
    }, [allComm, comments])

    const formik = useFormik({
        initialValues,
        onSubmit: (values, {resetForm}) => {
            setDisable(true);
            if (saveAuthor) {
                localStorage.setItem("comment-author", JSON.stringify({
                    ...values,
                    comments: ""
                }))
            }
            fetch(API_URL + "api/v1/comments", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    ...values,
                    id
                })
            })
                .then(res => res.json())
                .then(result => {
                    setDisable(false)
                    setResponse(result);
                    setTimeout(() => setResponse({}), 3000)
                    resetForm({
                        values: JSON.parse(localStorage.getItem("comment-author")) || initialValues
                    })
                })
        },
        validationSchema: loginSchema
    });


    return (
        <div className="replay-comment wrapper">
            {data.length ? <div className="post-comments" id="post-comments">
                {data.map((item, i) => (
                    <div key={item.id} className="comment-item">
                        <div className="head">
                            <div
                                style={{backgroundColor: staticColors[i % staticColors.length]}}
                                className="avatar">
                                {item.name[0]}
                            </div>
                            <h2 className="name">{item.name}</h2>
                            <p className="date">{dateFormat(item.updated_at)}</p>
                        </div>
                        <div className="comment-content">
                            <p>{item.comments}</p>
                        </div>
                    </div>
                ))}
                {comments.length > 5 ? <div
                    onClick={() => setAllComm(!allComm)}
                    className="view-all-comments">
                    {allComm ? "View Less" : "View All"}
                </div> : ""}
            </div> : ""}
            <h3>Leave a Reply</h3>
            <form className="leave-a-reply" onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="message-area">
                    <InputField
                        label="Comment"
                        element={<textarea
                            className={formik.touched.comments && formik.errors.comments && "error"}
                            id="comments"
                            name="comments"
                            placeholder="Your comment *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.comments}
                        />}
                        error={formik.touched.comments && formik.errors.comments}
                    />
                </div>
                <div className="fields">
                    <InputField
                        id="name"
                        name="name"
                        type="text"
                        label="Your Name"
                        placeholder="Name (required) *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        error={formik.touched.name && formik.errors.name}
                    />
                    <InputField
                        id="email"
                        name="email"
                        label="Email Address"
                        placeholder="Email (required) *"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={formik.touched.email && formik.errors.email}
                    />
                    <InputField
                        id="website"
                        name="website"
                        type="text"
                        label="Website"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.website}
                        error={formik.touched.website && formik.errors.website}
                    />
                </div>
                <p className={`message ${!response?.message ? "" : response?.success === false ? "error" : "success"}`}>{response?.message}</p>
                <label className="save-author">
                    <input
                        onChange={() => setSaveAuthor(!saveAuthor)}
                        type="checkbox"
                        checked={saveAuthor}
                    />
                    <span> Save my name, email, and website in this browser for the next time I comment.</span>
                </label>
                <div className="submit-comment">

                    <button
                        disabled={Object.keys(formik.errors).length ||
                            !Object.keys(formik.values).every(el => !!el === true) ||
                            disable
                        }
                        type="submit">Submit
                    </button>
                </div>
            </form>
        </div>
    )
}