import React, {useEffect, useRef} from "react";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import MainBanner from "../components/home/MainBanner";
import Faqs from "../components/home/Faqs";
import customer from "../data/customers.json";
import about from "../data/about.json";
import topics from "../data/topics.json"
import "./style.scss";
import BlogsAndNews from "../components/posts/BlogsAndNews";
import {Helmet} from "react-helmet-async";
import image from "../assets/images/about.jpg";

export default function Home() {
    const location = useLocation();
    const ref = useRef(null);

    useEffect(() => {
        if (location.hash === "#faqs" && ref) {
            ref.current.scrollIntoView({behavior: "smooth"});
        } else {
            window.scrollTo({top: 0})
        }
    }, [location, ref])

    return (
        <main className="page-main">
            <Helmet >
                <title>Fresno Traffic School</title>
                <meta name="title" content="Fresno Traffic School" />
                <meta name="description" content="Did you recently get a point on your California driving record? Are you worried about insurance premium increases? Then you should take online traffic school with us! Our DMV-approved traffic school course is the most comprehensive and clear traffic school course in California. And it’s only $19.95—the most affordable rate for California traffic school you’ll find! Here’s only some of what you’ll learn in our DMV-approved traffic school course:" />
                <meta property="og:title" content="Fresno Traffic School" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.fresnotraffic.school/"/>
                <meta property="og:image" content={image} />
            </Helmet>
            <MainBanner/>
            <div className="for-customers">
                {customer.info.map((item, index) => (
                    <div key={index} className="customer-info-item">
                        <div className="circle">
                            <div className="img-area">
                                <span dangerouslySetInnerHTML={{__html: item.img}}></span>
                            </div>
                        </div>
                        <h4>{item.title}</h4>
                        <p>{item.content}</p>
                        {index < 2 ? <a
                            href={item.slug}
                            target="_blank"
                            className="btn-flip"
                            data-back={item.btnName}
                            data-front={item.btnName} rel="noreferrer"
                        > </a> : <Link to={item.slug}
                                       className="btn-flip"
                                       data-back={item.btnName}
                                       data-front={item.btnName} rel="noreferrer"
                        >
                        </Link>}
                    </div>
                ))}
            </div>
            <div className="about-section">
                <div className="img-area">
                    <img src={require("../assets/images/about.jpg")} alt="about-us"/>
                </div>
                <div className="content">
                    <h2 className="question">{about.section.question}</h2>
                    <p className="text">{about.section.content}</p>
                    <div className="lists">
                        <ul>{about.section.list1.map((item, index) => (
                            <li key={index}>
                                <span className="icon-checkmark-outline"></span>
                                {item}</li>
                        ))}</ul>
                        <ul>{about.section.list2.map((item, index) => (
                            <li key={index}>
                                <span className="icon-checkmark-outline"></span>
                                {item}</li>
                        ))}</ul>
                    </div>
                    <p className="end">{about.section.end}</p>
                    <div className="about-btn">
                        <button className="btn rounded">
                            <a className="text-green" href={about.section.slug} target="_blank">Read More</a>
                        </button>
                    </div>
                </div>
            </div>
            <div className="topics">
                <h3 className="topics-title">{topics.title}</h3>
                <h2 className="topics-description">{topics.description}</h2>
                <div className="container">
                    {topics.data.map((item, index) => (
                        <div className="topic-item" key={index}>
                            <a href={item.slug} target="_blank" rel="noreferrer">
                                <div className="img-area">
                                    <img src={require(`../assets/images/topics/${item.img}`)} alt={item.title}
                                         title="topic"/>
                                </div>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div ref={ref}>
                <Faqs/>
            </div>
                <BlogsAndNews/>
        </main>
    )
}