export default function Footer () {
    return(
        <footer>
            <div className="rights">
                <p>
                    © 2022 - Fresno Traffic School. All Rights Reserved.
                </p>
            </div>
        </footer>
    )
}