import {useEffect, useState} from "react";
import {API_URL} from "../../constant";
import Loader from "../../components/common/Loader";
import PostItem from "../../components/posts/PostItem";
import "../../components/posts/style.scss";
import "./style.scss"
import {useParams} from "react-router";

export default function Posts() {
    const {type} = useParams();
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        window.scrollTo({top: 0})
        fetch(`${API_URL}api/v1/category/${type}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(res => res.json())
            .then(result => {
                setPosts(result.data.reverse())
            })
            .catch(err => console.log(err, 'err'))
    }, []);

    return (
        <div className="posts-page">
            <div className="posts-breadcrumbs"><h2>{type}</h2></div>
            {!posts ? <div className="empty-wrapper"><Loader/></div> :
                <div className="posts-wrapper articles_section">{
                    posts.map(item => <PostItem key={item.id} item={item}/>)
                }</div>
            }
        </div>
    )
}