import {API_URL} from "../../constant";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import PostItem from "./PostItem";
import "./style.scss";

export default function BlogsAndNews() {
    const [blogs, setBlogs] = useState(null);
    const [news, setNews] = useState(null);

    useEffect(() => {
        fetch(`${API_URL}api/v1/category/news`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(res => res.json())
            .then(result => {
                setNews(result.data.reverse().slice(0, 2));
            })
            .catch(err => console.log(err, 'err'))

        fetch(`${API_URL}api/v1/category/blog`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(res => res.json())
            .then(result => {
                setBlogs(result.data.reverse().slice(0, 2));
            })
            .catch(err => console.log(err, 'err'))
    }, []);

    // console.log(blogs, "blogs")
    // console.log(news, "news")

    return (
        <div className="blogs-and-news wrapper">
            {blogs ? <div className="category articles_section">
                <div className="view-all"><Link to="/category/blog">View All Blogs <span>⮞</span></Link></div>
                <div className="flex category-section">
                    {blogs.map((item, i) => <PostItem newest={true} item={item} key={item.id + i}/>)}
                </div>
            </div>: ""}
            {news ? <div className="category articles_section">
                <div className="view-all"><Link to="/category/news">View All News <span>⮞</span> </Link></div>
                <div className="flex category-section">
                    {news?.map((item, i) => <PostItem newest={true} item={item} key={item.id + i}/>)}
                </div>
            </div>: ""}
        </div>
    )

}