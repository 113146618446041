import React, {useState} from 'react';

export default function InputField({element, type, id, label, value, error, onChange, name, onBlur, placeholder}) {
    const [view, setView] = useState(false)

    return (
        <div className="input-field">
            {type === "password" ?
                <div
                    onClick={() => setView(!view)}
                    className="view-pass">
                    {view ? <span className="icon-eye-blocked"></span> :
                        <span className="icon-eye"></span>
                    }
                </div>
                : ""
            }
            <p className="label">{label}</p>
            {element ? element : <input
                type={type !== "password" ? type :
                    view ? "text" : "password"
                }
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder || label}
                autoComplete="off"
                className={error ? "error" : ""}
            />}
            <p className="error">{error}</p>
        </div>
    )
}