import {useFormik} from "formik";
import * as yup from 'yup';
import InputField from "../common/InputField";

// const loginSchema = yup.object({
//     email: yup.string().email('Invalid email').required('No email provided'),
//     password: yup.string()
//         .required('No password provided')
//         .min(8, 'Password is too short - should be 8 chars minimum'),
// });

export default function SignIn() {

    // const formik = useFormik({
    //     initialValues: {
    //         email: "",
    //         password: ""
    //     },
    //     onSubmit: (values, {resetForm}) => {
    //         window.location.href = "https://www.rapidtrafficschool.com/STUDENT-LOGIN/";
    //         resetForm()
    //     },
    //     validationSchema: loginSchema
    // });
    return (
        <div className="sign-in">
            <div className="login-title">
                <span className="icon-user"></span>
                <h3>Login Here</h3>
            </div>
            <form
                className='auth-form'
                // onSubmit={formik.handleSubmit}
                onSubmit={(e)=> {
                    e.preventDefault()
                    window.location.href = "https://www.rapidtrafficschool.com/STUDENT-LOGIN/";
                }}
                autoComplete="off">
                <InputField
                    id="email"
                    name="email"
                    label="Email Address"
                    type="text"
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.email}
                    // error={formik.touched.email && formik.errors.email}
                />

                <InputField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.password}
                    // error={formik.touched.password && formik.errors.password}

                />
                <button
                    // disabled={Object.keys(formik.errors).length || !Object.keys(formik.values).every(el => !!el === true)}
                    type="submit">Login
                </button>
            </form>
        </div>
    )
}