import {Link} from "react-router-dom";

export default function PostItem ({item, newest}){

    return(
        <div className='nth_article'>
            {newest ? <div className="new-indicator">NEW</div> : ""}
            <div className='image'>
                <img src={item.image} alt='Article'/>
            </div>
            <div className='article_nth_info'>
                <h5 className='date'><span className="icon-calendar"></span> {new Date(item.updated_at).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</h5>
                <h4 className='text'>{item.title}</h4>
                <p className="description">{item.excerpt}</p>
                <Link to={ `/${item.slug}/`}>
                    <div className='postBtn'>
                        <button>Read More</button>
                    </div>
                </Link>
            </div>
        </div>
    )
}