import "./style.scss"
import {useFormik} from "formik";
import * as yup from "yup";
import InputField from "../../components/common/InputField";
import {useState} from "react";
import {API_URL} from "../../constant";

const loginSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email('Invalid email').required('No email provided'),
    subject: yup.string().required("Subject is required"),
});

export default function ContactUs() {
    const [response, setResponse] = useState({})

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            subject: "",
            message: ""
        },
        onSubmit: (values, {resetForm}) => {
            fetch(API_URL + "api/email", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(values)
            })
                .then(res => res.json())
                .then(result => {
                    setResponse(result);
                    setTimeout(() => setResponse({}), 3000)
                    resetForm()
                })
        },
        validationSchema: loginSchema
    });

    return (
        <main className="contact-main">
            <div className="contact-about">
                <h2 className="title">Contact Us</h2>
                <p className="content">
                    <p>We’d be happy to hear your feedback or questions about our traffic school course! Feel free to reach out via phone or email (information below) or by using the form to the right. Our agents are available seven days a week from 7:30 a.m. to 5 p.m. PST. If you don’t receive an immediate reply to your message, you can expect it within one business day.</p>
                    <p></p>
                   <p> We appreciate your help with improving Fresno Traffic School!</p>
                </p>
                <div className="contacts">
                    <div className="contact phone">
                        <a href="tel:+5594731361" className="twitter-follow-button" data-show-count="false"
                           data-size="large" data-dnt="false"
                        >
                            <span className="icon-phone icon"></span>
                            <div className="inner">
                                <p className="place">Phone:</p>
                                <p>(559) 473-1361</p>
                            </div>
                        </a>
                    </div>
                    <div className="contact email">
                        <a href="mailto:info@fresnotraffic.school" className="twitter-follow-button"
                           data-show-count="false"
                           data-size="large" data-dnt="false"
                        >
                            <span className="icon-envelop icon"></span>
                            <div className="inner">
                                <p className="place">Email:</p>
                                <p>info@fresnotraffic.school</p>
                            </div>
                        </a>
                    </div>
                    <div className="contact web">
                        <a href="https://www.fresnotraffic.school/" className="twitter-follow-button"
                           data-show-count="false"
                           data-size="large" data-dnt="false"
                        >
                            <span className="icon-sphere icon"></span>
                            <div className="inner">
                                <p className="place">Website:</p>
                                <p>https://www.fresnotraffic.school</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="contact-form">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <InputField
                        id="name"
                        name="name"
                        type="text"
                        label="Your Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        error={formik.touched.name && formik.errors.name}
                    />
                    <InputField
                        id="email"
                        name="email"
                        label="Email Address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={formik.touched.email && formik.errors.email}
                    />
                    <InputField
                        id="subject"
                        name="subject"
                        type="text"
                        label="Subject"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subject}
                        error={formik.touched.subject && formik.errors.subject}
                    />
                    <div className="message-area">
                        <p>Your Message (optional)</p>
                        <textarea
                            id="message"
                            name="message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                        />
                    </div>
                    <p className={`message ${!response?.message ? "" : response?.success === false ? "error" : "success"}`}>{response?.message}</p>
                    <button
                        disabled={Object.keys(formik.errors).length || !Object.keys(formik.values).every(el => !!el === true)}
                        type="submit">Submit
                    </button>
                </form>
            </div>
        </main>
    )
}