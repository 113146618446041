import data from "../../data/banner.json"
import SignIn from "./SignIn";

export default function MainBanner() {
    return (
        <section className="main-banner">
            <div   className="background-img">
            </div>
            <div className="content">
                <div className="banner-points">
                    <h2 className="title">{data.title}</h2>
                    <ul>
                        {data.points.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <div className="btn-container">
                        <button className="btn rounded">
                            <a className="text-green" href="https://www.rapidtrafficschool.com/student/register/" target="_blank">Sign Up</a>
                        </button>
                    </div>
                </div>
                <SignIn/>
            </div>
        </section>
    )
}