import logo from "../../assets/images/fresnotraffic-logo.png"
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";

export default function Header() {
    const headerRef = useRef(null);
    const toTopRef = useRef(null);
    const [menu, setMenu] = useState(false)

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 48) {
            headerRef.current.classList.add("blur");
            toTopRef.current.classList.add("available")
        } else {
            if (headerRef.current.classList.contains("blur")) {
                headerRef.current.classList.remove("blur")
            }
            if (toTopRef.current.classList.contains("available")) {
                toTopRef.current.classList.remove("available")
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setMenu(!menu);
        document.body.classList.toggle("ov-hidden")
    }

    return (
        <>
            <div className="top-panel">
                <div className="info tel-info">
                    <span className="icon-phone icon"></span>
                    <a href="tel:+5594731361">(559) 473-1361</a>
                </div>
                <div className="info email-info icon">
                    <span className="icon-envelop icon"></span>
                    <a href="mailto:info@fresnotraffic.school">info@fresnotraffic.school</a>
                </div>
            </div>
            <header ref={headerRef}>
                <Link
                    onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
                    className="logo" to="/">
                    <img src={logo} alt="logo" title="logo"/>
                </Link>
                <nav>
                    <div className="nav-item">
                        <Link to="/">Home</Link>
                    </div>
                    <div className="nav-item">
                        <a href="https://www.rapidtrafficschool.com/student/register/">Register</a>
                    </div>
                    <div className="nav-item">
                        <Link
                            to={{
                                pathname: "/",
                                hash: "#faqs",
                            }}
                        >
                            FAQs
                        </Link>
                    </div>
                    <div className="nav-item">
                        <Link to="/contact-us">Contact Us</Link>
                    </div>
                </nav>
                <div
                    onClick={toggleMenu}
                    className="menu-burger"
                >
                    <span className="icon-menu"></span>
                    {menu ? <span className="close">＋</span> : ""}
                </div>
                {menu && <span onClick={toggleMenu} className="bg-phone"></span>}
                <div className={`mobile-menu ${menu ? "active" : ""}`}>
                    <div className="menu-logo">
                        <img src={logo} alt="logo" title="logo"/>
                    </div>
                    <ul
                        onClick={toggleMenu}
                    >
                        <li><Link to="/">Home</Link></li>
                        <li><a href="https://www.rapidtrafficschool.com/student/register/">Register</a></li>
                        <li><Link
                            to={{
                                pathname: "/",
                                hash: "#faqs",
                            }}
                        >
                            FAQs
                        </Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                        <li>
                            <a className="info tel-info" href="tel:+5594731361">
                                <span className="icon-phone icon"></span>
                                <span>(559) 473-1361</span>
                            </a>
                        </li>
                        <li>
                            <a className="info email-info icon" href="mailto:info@fresnotraffic.school">
                                <span className="icon-envelop icon"></span>
                                <span>info@fresnotraffic.school</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </header>
            <div
                ref={toTopRef}
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }}
                className="to-top">
                <p>🡩</p>
            </div>
        </>
    )
}